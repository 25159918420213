<!-- eslint-disable vue/no-mutating-props -->
<template>
  <card>

    <h5 slot="header" class="title">Check information</h5>
    <div class="col-md-5 pr-md-1">
      <base-select :options="optionsArea" label="Choose Area" v-model="adminInput.areaName" name="item-areaName">
      </base-select>
    </div>
    <div class="col-md-5 pr-md-1">
      <base-input type="datetime-local" label="From Time" v-model="adminInput.fromTime" name="item-fromTime" :value="defaultFromDate">
      </base-input>
    </div>
    <div class="col-md-5 pr-md-1">
      <base-input type="datetime-local" label="To Time" v-model="adminInput.toTime" name="item-toTime" :value="defaultFromDate">
      </base-input>
    </div>

    <base-button @click=getInfoArea()>Search</base-button>
    <div class="col-md-4 px-md-1">
      <base-input :readonly="true" label="Volume Trade" v-model="dataUser.volume" name="Volume">
      </base-input>
    </div>
    <div class="col-md-4 px-md-1">
      <base-input :readonly="true" label="Trade Commission" v-model="dataUser.trading_commissions" name="Trade Commission">
      </base-input>
    </div>
    <div class="col-md-4 px-md-1">
      <base-input :readonly="true" label="Trade Commission Vip" v-model="dataUser.vip_commissions" name="VIP Commission">
      </base-input>
    </div>
    <div class="col-md-4 px-md-1">
      <base-input :readonly="true" label="Deposit" v-model="dataUser.deposit_amount" name="Deposit">
      </base-input>
    </div>
    <div class="col-md-4 px-md-1">
      <base-input :readonly="true" label="Withdraw" v-model="dataUser.withdraw_amount" name="Withdraw">
      </base-input>
    </div>
    <div class="col-md-4 px-md-1">
      <base-input :readonly="true" label="Profit" v-model="dataUser.profit" name="Profit">
      </base-input>
    </div>
    <div class="col-md-4 px-md-1">
      <base-input :readonly="true" label="Total USDT" v-model="totalU" name="Commission">
      </base-input>
    </div>







  </card>
</template>
<script>
import AuthenticationService from '../services/AuthenticationService';
import { VueSelect, VueSelectItem } from 'vue-select'
import BaseDropdown from '../../components/BaseDropdown.vue';
import BaseSelect from '../../components/BaseSelect.vue';


export default {
  props: {
    data: {
      type: Object,
      default: () => { }

    }
  },
  components: {
    'vs-select': VueSelect,
    'vs-select-item': VueSelectItem,
    'base-select': BaseSelect,



  },
  data() {
    const now = new Date();
    const yesterday = new Date(now);
    yesterday.setDate(now.getDate() - 1);
    const monthYesterday = (yesterday.getMonth() + 1).toString().padStart(2, '0');
    const dateYesterday = yesterday.getDate().toString().padStart(2, '0');
    const fromTime = `${yesterday.getFullYear()}-${monthYesterday}-${dateYesterday}T07:00`;

    const monthToday = (now.getMonth() + 1).toString().padStart(2, '0');
    const dateToday = now.getDate().toString().padStart(2, '0');
    const toTime = `${now.getFullYear()}-${monthToday}-${dateToday}T07:00`;



    return {
      // defaultFromDate: new Date().toISOString().slice(0, 10) + 'T07:00',
      adminInput: {
        areaName: "",
        fromTime: fromTime,
        toTime: toTime,
      },
      optionsArea: [
        { value: "BIT", label: "BITTRADE" },
      ],
      dataUser: {
        volume: 0,
        trading_commissions: 0,
        vip_commissions: 0,
        deposit_amount: 0,
        withdraw_amount: 0,
        profit: 0,
        usdtAvailable: 0,
      },
      total: {
        volume: 0,
        trading_commissions: 0,
        vip_commissions: 0, 
        deposit_amount: 0,
        withdraw_amount: 0,
        profit: 0,
        usdtAvailable: 0,
      },
      areaName: "",
      totalU: 0,



    }
  },
  methods: {

    getInfoArea() {
      let obj = {
        areaName: this.adminInput.areaName,
        fromTime: this.adminInput.fromTime,
        toTime: this.adminInput.toTime,
      }
      AuthenticationService.getStaticsArea(obj)
        .then((res) => {
          if (res.data.success) {
            this.dataUser = res.data.data;
            this.totalU = res.data.total;

            const dataUser = res.data.data.reduce((acc, curr) => {
              acc.volume += curr.volume;
              acc.trading_commissions += curr.trading_commissions;
              acc.vip_commissions += curr.vip_commissions;
              acc.deposit_amount += curr.deposit_amount;
              acc.withdraw_amount += curr.withdraw_amount;
              acc.profit += curr.profit;
              acc.usdtAvailable = curr.usdtAvailable;
              return acc; // Return accumulated data instead of 'dataUser'
            }, {
              volume: 0,
              trading_commissions: 0,
              vip_commissions: 0,
              deposit_amount: 0,
              withdraw_amount: 0,
              profit: 0,
              usdtAvailable: 0
            });
            dataUser.volume = parseFloat(dataUser.volume.toFixed(2));
            dataUser.trading_commissions = parseFloat(dataUser.trading_commissions.toFixed(2));
            dataUser.vip_commissions = parseFloat(dataUser.vip_commissions.toFixed(2));
            dataUser.deposit_amount = parseFloat(dataUser.deposit_amount.toFixed(2));
            dataUser.withdraw_amount = parseFloat(dataUser.withdraw_amount.toFixed(2));
            dataUser.profit = parseFloat(dataUser.profit.toFixed(2));
            dataUser.usdtAvailable = parseFloat(dataUser.usdtAvailable.toFixed(2));
            this.dataUser = dataUser;
            return this.$vs.notification({
              text: 'Đã lấy thông tin thành công ',
              color: 'success',
              iconPack: 'feather',

            });
          } if (res.data.success === false && res.data.errorType == "invalidAccessToken") {
            this.$router.push('/hadaleon').catch(() => { })
            localStorage.removeItem("accessToken");
            localStorage.removeItem("refreshToken");
            localStorage.removeItem("INFO");
            return this.$vs.notification({
              text: 'Phiên đăng nhập đã hết hạn! ',
              color: '#4B0082',
            });
          } if (res.data.success == false && res.data.errorType == '') {
            return this.$vs.notification({
              text: "Không thể lấy thông tin !",
              color: 'danger',
              iconPack: 'feather',
              icon: 'icon-alert-circle'
            });
          }
        })
    },







  },
  computed: {
        defaultFromDate() {
      const now = new Date();
      const year = now.getFullYear();
      const month = now.getMonth();
      const day = now.getDate();
      const time = "07:00 AM";
      return `${year}-${month.toString().padStart(2, "0")}-${day.toString().padStart(2, "0")}T${time}`;
    },
  },
}

</script>
<style></style>
